<template>
    <div>
        <create-new-category
            mode="edit"
            subcategory
            :error-text="errorText"
            :errors-sub-categories="errorsSubCategories"
            :main-category="category"
            :content-type="'essay'"
            @on-save-btn-click="updateCategory($event)"
            @deleteSubCategory="deleteSubCategory($event)"
            @saveSubCategory="createSubCategory($event)"
            @updateSubCategory="updateSubCategory($event)"
            @input="resetError"
            @inputSubCategory="resetSubError"
        />
        <confirm-modal
            :name="'delete-subcategory-modal'"
            :title="'Delete category'"
            :content="'Are you sure?'"
            @onConfirmClick="deleteSubCategory($event)"
        />
    </div>
</template>

<script>
import CreateNewCategory from '@/components/create-category/CreateNewCategory';
import ConfirmModal from '@/components/modals/ConfirmModal';
import categoryBaseMixins from '@/mixins/content/categoryBaseMixins';

export default {
    name: 'IndexVue',
    components: {
        CreateNewCategory,
        ConfirmModal
    },
    mixins: [categoryBaseMixins]
}
</script>

<style lang="scss" scoped>

</style>
